import React from "react";
import theme from "theme";
import { Theme, Text, Em, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"privacy-policy"} />
		<Helmet>
			<title>
				Gris
			</title>
			<meta name={"description"} content={"Step into an emotional, visually stunning world and help Gris on her journey of self-discovery and healing."} />
			<meta property={"og:title"} content={"Gris"} />
			<meta property={"og:description"} content={"Step into an emotional, visually stunning world and help Gris on her journey of self-discovery and healing."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6724afdd41a0cf0024a5268a/images/DALL_E_2024-11-01_14.44.00_-_A_minimalist_favicon_for_the_game_Gris__featuring_a_simple__abstract_symbol_inspired_by_Gris_s_journey_and_the_watercolor_art_style_of_the_game._The_i-removebg-preview.png?v=2024-11-01T13:12:09.256Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6724afdd41a0cf0024a5268a/images/DALL_E_2024-11-01_14.44.00_-_A_minimalist_favicon_for_the_game_Gris__featuring_a_simple__abstract_symbol_inspired_by_Gris_s_journey_and_the_watercolor_art_style_of_the_game._The_i-removebg-preview.png?v=2024-11-01T13:12:09.256Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6724afdd41a0cf0024a5268a/images/DALL_E_2024-11-01_14.44.00_-_A_minimalist_favicon_for_the_game_Gris__featuring_a_simple__abstract_symbol_inspired_by_Gris_s_journey_and_the_watercolor_art_style_of_the_game._The_i-removebg-preview.png?v=2024-11-01T13:12:09.256Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6724afdd41a0cf0024a5268a/images/DALL_E_2024-11-01_14.44.00_-_A_minimalist_favicon_for_the_game_Gris__featuring_a_simple__abstract_symbol_inspired_by_Gris_s_journey_and_the_watercolor_art_style_of_the_game._The_i-removebg-preview.png?v=2024-11-01T13:12:09.256Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6724afdd41a0cf0024a5268a/images/DALL_E_2024-11-01_14.44.00_-_A_minimalist_favicon_for_the_game_Gris__featuring_a_simple__abstract_symbol_inspired_by_Gris_s_journey_and_the_watercolor_art_style_of_the_game._The_i-removebg-preview.png?v=2024-11-01T13:12:09.256Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6724afdd41a0cf0024a5268a/images/DALL_E_2024-11-01_14.44.00_-_A_minimalist_favicon_for_the_game_Gris__featuring_a_simple__abstract_symbol_inspired_by_Gris_s_journey_and_the_watercolor_art_style_of_the_game._The_i-removebg-preview.png?v=2024-11-01T13:12:09.256Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6724afdd41a0cf0024a5268a/images/DALL_E_2024-11-01_14.44.00_-_A_minimalist_favicon_for_the_game_Gris__featuring_a_simple__abstract_symbol_inspired_by_Gris_s_journey_and_the_watercolor_art_style_of_the_game._The_i-removebg-preview.png?v=2024-11-01T13:12:09.256Z"} />
			<meta name={"msapplication-TileColor"} content={"#000848"} />
		</Helmet>
		<Components.Header />
		<Section>
			{"  "}
			<Text fontSize="24px" fontWeight="bold" font="--headline2">
				Privacy Policy
			</Text>
			{"  "}
			<Text>
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Last Updated: 20.10.2024
				</Em>
			</Text>
			{"  "}
			<Text>
				{"    "}This Privacy Policy describes our policies and procedures on the collection, use, and disclosure of your information when you use the TeravivoGames (the "Service"). It tells you about your privacy rights and how the law protects you.{"\n  "}
			</Text>
			{"  "}
			<Text>
				{"    "}By using the Service, you agree to the collection and use of information in accordance with this Privacy Policy.{"\n  "}
			</Text>
			{"  "}
			<Text fontWeight="bold" font="--headline4">
				1. Information We Collect
			</Text>
			{"  "}
			<Text>
				{"    "}While using our Service, we may ask you to provide us with certain personally identifiable information, including but not limited to:{"\n  "}
			</Text>
			{"  "}
			<Text>
				- Name
			</Text>
			{"  "}
			<Text>
				- Email address
			</Text>
			{"  "}
			<Text>
				- Phone number
			</Text>
			{"  "}
			<Text>
				- Usage Data
			</Text>
			{"  "}
			<Text fontWeight="bold" font="--headline4">
				2. Usage Data
			</Text>
			{"  "}
			<Text>
				{"    "}Usage Data is collected automatically, including information such as your device's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, and other diagnostic data.{"\n  "}
			</Text>
			{"  "}
			<Text fontWeight="bold" font="--headline4">
				3. Cookies and Tracking Technologies
			</Text>
			{"  "}
			<Text>
				{"    "}We use cookies and similar tracking technologies to monitor activity on our Service and store certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.{"\n  "}
			</Text>
			{"  "}
			<Text fontWeight="bold" font="--headline4">
				4. Use of Your Personal Data
			</Text>
			{"  "}
			<Text>
				{"    "}TeravivoGames may use personal data for the following purposes:{"\n  "}
			</Text>
			{"  "}
			<Text>
				- To provide and maintain our Service
			</Text>
			{"  "}
			<Text>
				- To manage your account and provide customer support
			</Text>
			{"  "}
			<Text>
				- To contact you with updates and promotional information
			</Text>
			{"  "}
			<Text fontWeight="bold" font="--headline4">
				5. Disclosure of Your Personal Data
			</Text>
			{"  "}
			<Text>
				{"    "}We may disclose your personal data in the following situations:{"\n  "}
			</Text>
			{"  "}
			<Text>
				- With Service Providers to monitor and analyze the use of our Service
			</Text>
			{"  "}
			<Text>
				- For business transfers in connection with any merger or acquisition
			</Text>
			{"  "}
			<Text>
				- With your consent for any other purpose
			</Text>
			{"  "}
			<Text fontWeight="bold" font="--headline4">
				6. Security of Your Personal Data
			</Text>
			{"  "}
			<Text>
				{"    "}The security of your personal data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure.{"\n  "}
			</Text>
			{"  "}
			<Text fontWeight="bold" font="--headline4">
				7. Children’s Privacy
			</Text>
			{"  "}
			<Text>
				{"    "}Our Service does not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children under 13.{"\n  "}
			</Text>
			{"  "}
			<Text fontWeight="bold" font="--headline4">
				8. Changes to This Privacy Policy
			</Text>
			{"  "}
			<Text>
				{"    "}We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.{"\n  "}
			</Text>
			{"  "}
			<Text fontWeight="bold" font="--headline4">
				9. Contact Us
			</Text>
			{"  "}
			<Text>
				{"    "}If you have any questions about this Privacy Policy, please contact us:{"\n  "}
			</Text>
			{"  \n\t\t    "}
			<Text>
				{" "}Address: unit 19A/87 Catalano Cct,
Canning Vale WA 6155, Australia{" \n        "}
			</Text>
		</Section>
		<Components.Footer>
			<Override slot="text" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n  scroll-behavior: smooth;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});